import makeStyles from '@material-ui/core/styles/makeStyles';
import MainNavigation from '@sprinx/cvo-eshop-ui-common/layout/components/MainNavigation';
import MainNavigationNarrow from '@sprinx/cvo-eshop-ui-common/layout/components/MainNavigationNarrow';
import LayoutWebClassic from '@sprinx/react-mui-layout/LayoutWebClassic';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  appLayout: {
    background: theme.palette.background.default,
  },
}));

const AppLayout = React.forwardRef(({ children, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <LayoutWebClassic
      {...rest}
      maxWidth='lg'
      className={classes.appLayout}
      AppHeaderWideComponent={MainNavigation}
      AppHeaderNarrowComponent={MainNavigationNarrow}
      ref={ref}
    >
      {children}
    </LayoutWebClassic>
  );
});

AppLayout.displayName = 'AppLayout';

AppLayout.propTypes = {
  children: PropTypes.node,
};

AppLayout.defaultProps = {
  children: null,
};

export default AppLayout;
