import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cancelable, CancelablePromiseType } from 'cancelable-promise';
import qs from 'qs';

export { cancelable } from 'cancelable-promise';
export type { CancelablePromiseType } from 'cancelable-promise';

export interface ApiClient {
  delete: <R, P extends Record<string, any> = never>(
    path: string,
    params?: P,
    options?: AxiosRequestConfig,
  ) => CancelablePromiseType<R>;
  get: <R, P extends Record<string, any> = never>(
    path: string,
    params?: P,
    options?: AxiosRequestConfig,
  ) => CancelablePromiseType<R>;
  post: <R, P extends Record<string, any>>(
    path: string,
    params: P,
    options?: AxiosRequestConfig,
  ) => CancelablePromiseType<R>;
  put: <R, P extends Record<string, any>>(
    path: string,
    params: P,
    options?: AxiosRequestConfig,
  ) => CancelablePromiseType<R>;
}

export class ApiClientClass implements ApiClient {
  api: AxiosInstance;

  constructor(config: AxiosRequestConfig & { secureClientId?: string }) {
    const { secureClientId, ...axiosConfig } = config;

    this.api = axios.create({
      headers: {
        common: {
          ...(secureClientId ? { 'x-sx-client-id': secureClientId } : {}),
        },
      },
      paramsSerializer: (params) => qs.stringify(params),
      // timeout: 5000,
      timeout: 30000,
      ...axiosConfig,
    });
  }

  get<R, P extends Record<string, any> = never>(
    path: string,
    params?: P,
    options?: AxiosRequestConfig,
  ): CancelablePromiseType<R> {
    return cancelable(
      resultData(buildUrlString(path, params, (url, data) => this.api.get(urlWithQueryString(url, data), options))),
    );
  }

  post<R, P extends Record<string, any>>(
    path: string,
    params: P,
    options?: AxiosRequestConfig,
  ): CancelablePromiseType<R> {
    return cancelable(resultData(buildUrlString(path, params, (url, data) => this.api.post(url, data, options))));
  }

  put<R, P extends Record<string, any>>(
    path: string,
    params: P | null | undefined,
    options?: AxiosRequestConfig,
  ): CancelablePromiseType<R> {
    return cancelable(resultData(buildUrlString(path, params, (url, data) => this.api.put(url, data, options))));
  }

  delete<R, P extends Record<string, any> = never>(
    path: string,
    params?: P,
    options?: AxiosRequestConfig,
  ): CancelablePromiseType<R> {
    return cancelable(
      resultData(buildUrlString(path, params, (url, data) => this.api.delete(urlWithQueryString(url, data), options))),
    );
  }
}

function jsonParserReviver(key: string, value: any) {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;
  if (typeof value === 'string') {
    if (reISO.exec(value)) {
      return new Date(value);
    }

    const a = reMsAjax.exec(value);
    if (a) {
      const b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
  }
  return value;
}

function sanitizeData<T>(data: any): T {
  return JSON.parse(JSON.stringify(data), jsonParserReviver);
}

async function resultData<T>(responsePromise: Promise<AxiosResponse<T>>): Promise<T> {
  const { data } = await responsePromise;
  return data ? sanitizeData(data) : data;
}

/**
 * Vraci normalizovanou URI.
 *
 * @param {string} path
 *
 * @returns String root pathy bez duplicitnich lomitek
 */
function sanitizeUrl(path = ''): string {
  const result = path.replace(/^\//, '').replace(/\/+/g, '/').replace(/\/$/, '');

  return `/${result}/`;
}

export function replaceRouteParams<P extends Record<string, any>>(
  path: string,
  params: P | null | undefined,
): [string, Record<string, any> | undefined] {
  const replaced: Array<string | number> = [];
  const inp = params || ({} as P);

  const result = sanitizeUrl(
    path.replace(/\./g, '/').replace(/(:(\w+))/g, (str, keyWithColon, key) => {
      replaced.push(key);
      return encodeURIComponent(inp[key]);
    }),
  );

  const nextParams = Object.keys(inp).reduce(
    (np, key) => ({ ...np, ...(replaced.includes(key) ? {} : { [key]: inp[key] }) }),
    {},
  );
  return [result, params ? nextParams : undefined];
}

function buildUrlString<R, P extends Record<string, any>>(
  path: string,
  params: P | null | undefined,
  call: (url: string, data: Record<string, any> | undefined) => Promise<R>,
): Promise<R> {
  const [url, data] = replaceRouteParams(path, params);
  return call(url, data);
}

function urlWithQueryString(url: string, data: Record<string, any> | undefined): string {
  return data && Object.keys(data).length ? `${url}${qs.stringify(data, { addQueryPrefix: true })}` : url;
}
