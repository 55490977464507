/*
 * !!! This is only example / skeleton of an remote resource.
 * !!! Have to create implementation.
 */
import { CancelablePromiseType } from '../@sprinx/after-razzle';
import { LayoutData } from '../types/LayoutData';
import apiClient from './appApi';

export default {
  get: getResource,
};

function getResource(): CancelablePromiseType<LayoutData> {
  return apiClient.get<LayoutData, {}>('v1/commonData');
}
