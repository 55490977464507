/*
 * !!! This is only example / skeleton of an remote resource.
 * !!! Have to create implementation.
 */
import { CancelablePromiseType } from '../@sprinx/after-razzle';
import { HomePageData } from '../types/HomePageData';
import apiClient from './appApi';

export default {
  get: getResource,
};
type HPData = Omit<HomePageData, 'layout'>;

function getResource(): CancelablePromiseType<HPData> {
  return apiClient.get<HPData, {}>('v1/homepageData');
}
